body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0 !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.wrapper {
  display: inline-block;
  /* border: 1px solid black; */
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.ck-editor__editable_inline {
  min-height: 300px;
}


#tooltip {
  color: #222;
  background: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  font-size: 100%;
}

.shortcode {
  text-decoration: underline;
  font-weight: bold;
}